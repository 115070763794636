.ex2 {
    max-width: 450px;
}

.memo-bg{
    width: 300px;
    height: 79px;
    background: url('./memo.png');
    background-repeat : no-repeat;
    background-position : center;
}

.image {
    background-repeat: no-repeat;
    background-size : cover;
    background-position: center;
}

.r_inp {
    border-radius: 5px !important;
    border: 1px solid lightseagreen !important;
}

.col.s12 > .btn {
    width: 100%;
 }

.footer-fixed-login{
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100% !important;
    height: 50px !important ;
    background: #000;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .mtp0{
    margin-top:  0px !important;
    padding-top: 0px !important;
    margin-bottom:  0px !important;
    padding-bottom: 0px !important;
}

.mlp0{
   margin-left:  10px !important;
   padding-left: 0px !important;
   margin-right:  10px !important;
   padding-right: 0px !important;
}

.lbl-err-txt {
     color: red;    
     font-size: 14px;        
}