
.detailsearch-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  
  .frame{
    border: 1px solid red;
  }

  .centered-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }