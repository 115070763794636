body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: auto;
}

footer {
  background-color: #558197 !important;

}

.frame {
	border: 2px solid red;
}

.bframe {
  border: 1px solid blue;
}

.sidenav-trigger {
  display: none !important;
  padding: 5px;
  padding-left: 20px;
}

.nav-wrapper {
  display: flex;
}

.scc-light-card {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.04),0 2px 5px 0 rgba(0,0,0,0.09),0 1px 1px -2px rgba(0,0,0,0.5) !important;
  display: flex;
}

.scc-card-icon-container {
  display: flex;
  padding-left: 30px;
  justify-content: center;
  align-items: center;
}

.scc-marginTop10 {
  margin-top: 10px !important;
}

.scc-card-icon {
  font-size: 30px !important;
}

.input-col label{
  position: inherit;
}

.select-frame {
}


.parallax-container {
     height: 550px !important;
}

.scc-header-title {
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  text-align: left;
}

@media screen and (max-width: 992px){
  .sidenav-trigger {
    display: inline-block !important;
  }

  .scc-banner-container {
    height: 400px !important;
  }
  
}

@media screen and (max-width: 1122px){
  
  .scc-flex-center {
    justify-content: center !important;
  }

  .scc-header-title {
    text-align: center;
  }
}

@media screen and (max-width: 600px){
  
  .scc-left-border {
    border-left: none !important;
    border-top: 1px solid #E1E1E1;
    margin-top: 20px !important;
    padding-top: 20px !important;
  }
}

.scc-image-section {
      height:550px !important;
      display: block !important;
}

/*@media screen and (max-width:600px) {
   .scc-cards_item {
	  width: 50%;
	}

}*/
.scc-left-border {
  border-left: 1px solid #E1E1E1;
  height: 300px;
}

.scc-card_image img {
  height: auto;
  opacity: 0.8;
	max-width: 100%;
	vertical-align: middle;
}
  
.parallax img{
  display: inherit !important;
}

.scc-container {
	width: 100%;
}


.scc-graph-items {
	padding: 20px 10%;
}

.scc-cards-section {
	width: 100%;
	/*position: relative;*/
	padding-top: 35px;
	padding-bottom: 60px;
	background-color: rgba(243, 229, 251, 0.45);
}

.scc-cards-container {
	  display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     
}

.scc-cards-content {
	width: 80%;
}

.scc-cards-content ul {
  justify-content: center;
  align-items: center;
}

.scc-card-btn {
	color: #ffffff;
	padding: 0.8rem;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 4px;
	font-weight: 400;
	display: block;
	width: 100%;
	cursor: pointer;
	border: 1px solid rgba(255, 255, 255, 0.2);
	background: transparent;
}
  
.scc-card-btn:hover {
	background-color: rgba(255, 255, 255, 0.12);
}
  
.scc-cards {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
}
  
.scc-cards_item {
  display: flex;
  /*width: 26%;*/
	padding: 1rem;
}

  
.scc-card {
	background-color: white;
	border-radius: 0.25rem;
	box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
  
.scc-card_content {
  padding: 1rem;
  height: 220px;
	background-color: rgb(255, 255, 255);
}
  
.scc-card_title {
	color: #302e2e;
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 1px;
	margin: 0px;
}
  
.scc-card_text {
	color: #272626;
	font-size: 0.875rem;
	line-height: 1.5;
	margin-bottom: 1.25rem;    
	font-weight: 400;
}

.scc-graph-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.scc-contact-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.scc-contact-section-title {
  margin-bottom: 50px;
}

.parallax-container {
  overflow: hidden;
  position: absolute !important;
  background: rgba(74, 74, 74, 0.1);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.scc-page-container {
  padding-top: 130px;
}

html{
  margin:0 !important;
  padding:0 !important;
}

body {
    margin-bottom: 0 !important;
}

.container {
    margin-bottom: 0 !important;
}


.scc-page-content {
  /*position: relative;
  width: 100%;
  height: 100%;*/
}

.scc-min-height {
  min-height: calc(100vh - 100px) !important;
}

.scc-search-section {
  margin : auto;
}

.scc-table-header-text {
  color: #5d90ae;
}

.scc-search-footnote-eng {
  padding-left: 15px;
  font-size: 15px;
}

.scc-serch-section-filters {
  background-color: gray;
  height: 70px;
  margin-bottom: 10px;
}

.scc-image-section-content {
  width: 80%;
  height: 100%;
  z-index: 1100;
  position: relative;
  margin: auto ;
}

.scc-image-section-title {
  width: 100%;
  text-align: center;
  animation-timing-function: linear;
  animation-duration: .2s;
}


.scc-image-section-title h1 {
  color: white;
  text-align: center;
  text-shadow: 0em 0.1em 0.1em rgba(0,0,0,0.4);
  font-size: 75px;
  font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
}

.scc-image-section-button-container {
  text-align: center;
  width: 100%;
  margin-top: 30px;
}

.scc-image-section-button {
  border: 1px solid white;
  /*position: relative;*/
  cursor: pointer;
  border-radius: 20px;
  z-index: 10003;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #58808c;
}

.scc-image-section-button:hover {
  background-color: rgba(252, 250, 250, 0.6);
}


. {
  color:  black; 
}
/*
@media screen and (max-width: 33rem) {
	.scc-cards_item {
	  width: 100%;
	}
}
@media screen and (min-width: 33rem) {
	.scc-cards_item {
	  width: 100%;
	}
}
@media screen and (min-width: 40rem) {
	.scc-cards_item {
	  width: 50%;
	}
}
  
@media screen and (min-width: 76rem) {
	.scc-cards_item {
	  width: 26%;
	}
}*/

header {
	position: fixed;
	z-index: 2000;
	width: 100%;
}

/*month and day animation*/
@keyframes fadeAnimation{
 	0%{
   		opacity: 0;
  	}
  	50%{
   		opacity: 0.5;
  	}
  	100%{
   		opacity: 1;
  	}
}

.no_timeline_selection {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

.scc-login-button {
  border: 1px solid #44b775;
  border-radius: 20px;
  padding: 7px;
  color: #2a686b !important;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  display: inline;
  transition: 0.5s;
}


.scc-login-button:hover {
  background-color: rgba(95, 158, 160, 0.288);
}

.root-bg{
    min-height: calc(100vh - 200px)  !important;    
    background: url('./components/Main/calc.jpg');      
    width : 100% !important;;
    background-repeat : no-repeat;
    background-size : cover;
    background-position : center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content : center;
    padding : 0px;
    margin: 0px;
}

/*background-color: #58808c !important;*/
.footer{
  color: #fff;
  bottom: 0px;
  width: 100% !important;
  background-color: #f0f0f0 !important;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin: 0px;
  min-height: 300px !important;
}


.scc-header-username {
  margin-right: 10px;
  font-weight: 500; 
  color: #55b990 !important;
  float: left !important;
}

.scc-table-edit-btn {
  color : #26a69a;
  background-color: rgba(230, 225, 225, 0.253);
  padding : 5px;
  border-radius: 20px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}

.scc-tab-header {
  color : #26a69a;
  box-shadow: 0px -5px 17px rgba(0, 0, 0, 0.3);
}

/* MATERIALIZE TABS*/
.scc-tab {
 color:#26a69a!important;
 font-weight: normal;
}

.scc-tab-active {
  color:#26a69a!important;
  font-weight: bold;
}

.scc-tab-active:focus {
  background-color: rgba(38, 166, 154, 0.2) !important;
}

.tabs .indicator {
  background-color : #26a69a !important;
}

/* MATERIALIZE TABS*/

/*
.tabs .tab a {
  color: rgba(38, 166, 154, 0.7) !important;
  font-size: 16px;
}

.tabs .tab a:hover {
  color:#26a69a!important;
}

.tabs .tab a:focus.active {
  color:#26a69a;
  background-color: red !important;
}

.tabs .indicator {
  background-color : #26a69a !important;
}
*/

.scc-banner-container {
  height: 300px;
  width: 100%;
}

.scc-banner-content {
}

.multiselect-container {
  background-color: rgba(255,255,255,0.9);
  width: 100% !;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 3rem;
}

.multiselect-label {
  font-size: .8rem;
  color: #9e9e9e;
}

.react-datepicker-wrapper {
  width: 100%;
}

.scc-main-paragraph {
  color : gray;
  font-size : 20px;
  text-align : center;
}

.scc-banner-list {
  list-style-type: disc !important;
  margin-left: 30px;
  color: rgb(255, 255, 255);
}

.scc-banner-list li {
  list-style-type: disc !important;
  font-size: 20px;
}

.animated-header{
    animation-name: fadeAnimation;
    animation-duration: 2000ms;
    animation-delay: 500ms;
    animation-iteration-count: 1;
}
.character-counter {
  display: none !important;
}

.scc-table-cell {
  min-width: 160px;
  font-size: 14px;
}

.scc-comment-date {
    color: #5a5858b5;
    font-weight: 500;
    float: right;
}

.scc-count-container {
  padding-left: 10px;
}

.scc-count-label {
  font-size: 15px;
  font-family: sans-serif;
}

.scc-count-value {
  font-weight: bold;
  padding-left: 5px;
  color: #f4bd36;
}

.scc-flex-center {
  display: flex;
  justify-content: flex-end;
}

.scc-input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-sizing: border-box;
}

.centered-container {
    display: flex;    
    flex-wrap: wrap;      
    justify-content: center;
    align-items: center;     
}

.spacebtw-container {
    display: flex;    
    flex-wrap: wrap;      
    justify-content: space-between;
    align-items: center;     
}

.right-container {
    display: flex;    
    flex-wrap: wrap;      
    justify-content: flex-end;
    align-items: center;     
}

.centered-container-column {
    display: flex;        
    flex-direction: column;   
    justify-content: center;
    align-items: left;     
}

a {
  cursor: pointer !important;
}

.h100{
  height: 100% !important;
}

#toast-container {
    top: auto !important;
    right: auto !important;
    bottom: 50%;
    left:50%;  
}

.container {
    width: 90% !important;
}

.lbl-err-txt {
     color: red;    
     font-size: 12px;        
}

.openSansHeader {
    font-family: Open Sans, Arial, sans-serif;
    /*font-family: GillSans, Calibri, Trebuchet, sans-serif;*/
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    color: #313a50;
}

@media screen and (max-width: 600px) {
.mainText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;;
    
    font-weight: 500;
    font-style: normal;
    font-size: 54px;
    color: #ffffff;
    text-shadow: 2px 2px 10px #222222;
    line-height: 76px;
}
}

@media screen and (min-width: 601px) {
.mainText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;;
    
    font-weight: 500;
    font-style: normal;
    font-size: 76px;
    color: #ffffff;
    text-shadow: 2px 2px 10px #222222;
    line-height: 76px;
}
}


@media screen and (min-width: 601px) {
.mainTextSmall {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;;
    
    font-weight: 500;
    font-style: italic;
    font-size: 32px;
    color: #ffffff;
    text-shadow: 2px 2px 10px #222222;
    line-height: 32px;
    margin-top: 10px;
}
}

@media screen and (max-width: 600px) {
.mainTextSmall {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;;
    
    font-weight: 500;
    font-style: italic;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 2px 2px 10px #222222;
    line-height: 32px;
    margin-top: 10px;
}
}
.pretragaText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px #111111;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #fff;
    line-height: 26px;
}

.whoAreWe {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;;
    
    font-weight: 500;
    font-style: normal;
    font-size: 46px;
    color: #0f7e9b;
    line-height: 47px;
    margin-top: 15px;
    margin-bottom: 15px;
}

@media screen and (min-width: 601px) {
.graphText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    
    font-weight: 500;
    font-style: normal;
    font-size: 19px;
    color: #000000;
    line-height: 25px;
}
}

@media screen and (max-width: 600px) {
.graphText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    color: #000000;
    line-height: 23px;
}
}

@media screen and (min-width: 601px) {
.cardText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #000000;
    line-height: 26px;
}
}

@media screen and (max-width: 600px) {
  .cardText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
}
}

@media screen and (min-width: 601px) {
.scc-banner-title h5 {
  color : white;
  font-style: normal;
  text-align: center;
  font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 22px;
  padding: 0px;
}
}

@media screen and (max-width: 600px) {
  .scc-banner-title h5 {
  color : white;
  font-style: normal;
  text-align: center;
  font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 19px;
  padding: 0px;
}
}
.cardHeadline {
    font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;;
    /*font-family: GillSans, Calibri, Trebuchet, sans-serif;*/
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    color: #313a50;
}

.searchBannerText {
    /*font-family: Open Sans, Arial, sans-serif;*/
    font-family: Raleway, Helvetica, Arial, Lucida, sans-serif;
    
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: #000000;
    line-height: 26px;
}



@media screen and (max-width: 600px) {
  .show_on_phone_only {
    display: flex;
  }
}

@media screen and (min-width: 601px) {
  .show_on_phone_only {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .hide_on_phone {
    display: none;
  }
}

@media screen and (min-width: 601px) {
  .hide_on_phone {
    display: flex;
  }
}


.container {
  margin: 0 auto;
  width: 90% !important;
}
@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}