.mycontainer {
  margin: 0 auto;
  width: 90% !important;
}

.scc-sc-header input {
    outline: none;
    color: black !important;
    height: 17px !important;
}
.scc-sc-header input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}
.scc-sc-header input::-webkit-search-decoration,
.scc-sc-header input::-webkit-search-cancel-button {
	display: none; 
}


.scc-sc-header input[type=search] {
	background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
	border: solid 1px #ccc;
	padding: 9px 10px 9px 32px;
    width: 130px;	
	-webkit-border-radius: 10em;
	-moz-border-radius: 10em;
	border-radius: 10em;
	
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	transition: all .5s;
}
.scc-sc-header input[type=search]:focus {
	width: 130px;
	background-color: #fff;
}


.scc-sc-header input:-moz-placeholder {
	color: #999;
}
.scc-sc-header input::-webkit-input-placeholder {
	color: #999;
}

.scc-header-image {
	vertical-align: middle;
	margin-top: -5px;
	margin-bottom: 2px;
}
